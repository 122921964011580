/* eslint-disable no-console */
import axios from "axios";
import { isAuthenticated } from "./isAuthenticated";
import TokenService from "./token.service";

const instance = axios.create({
  baseURL: "https://webapi.iyosa.com/",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": true,
  },
});

instance.interceptors.request.use(
  (config) => {
    if (!isAuthenticated()) return config;
    const token = TokenService.getLocalAccessToken();
    if (token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.authorization = `Bearer ${token}`; // for Spring Boot back-end
      // config.headers["x-access-token"] = token;
      // for Node.js Express back-end
    }
    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  // eslint-disable-next-line arrow-body-style
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    console.log(err);

    if (originalConfig.url !== "public/login" && err.response) {
      // Access Token was expired
      // eslint-disable-next-line no-underscore-dangle
      if (err.response.status === 401 && !originalConfig._retry) {
        // eslint-disable-next-line no-underscore-dangle
        originalConfig._retry = true;

        try {
          const rs = await instance.post("refresh-tokens", {
            refreshToken: TokenService.getLocalRefreshToken(),
          });

          TokenService.updateLocalAccessToken(rs.data);

          return instance(originalConfig);
        } catch (_error) {
          TokenService.removeUser();
          window.location.href("/authentication/sign-in");
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;
