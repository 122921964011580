/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Icon } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import RequestService from "services/request.service";
import slugify from "services/slugify";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function BlogEdit() {
  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");
  const [id, setId] = useState("");
  const [image, setImage] = useState("");
  const [content, setContent] = useState("");
  const [message, setMessage] = useState({ isShow: false, variant: "", msg: "" });
  const { paramUrl } = useParams();
  const history = useNavigate();
  useEffect(() => {
    let isApiSubscribed = true;
    if (paramUrl) {
      RequestService.get(`public/blog/url`, paramUrl)
        .then((res) => {
          if (isApiSubscribed) {
            const resData = res.data.result;
            setTitle(resData.title);
            setImage(resData.image);
            setContent(resData.content);
            setUrl(resData.url);
            setId(resData.id);
          }
        })
        .catch((error) => {
          const resMessage =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          setMessage({ isShow: true, variant: "error", msg: resMessage });
          setTimeout(() => {
            history("/blogs");
          }, 2000);
        });
    }
    return () => {
      isApiSubscribed = false;
    };
  }, []);
  function handleSave() {
    const data = { title, image, content, url };
    RequestService.update("private/blog", id, data)
      .then((res) => {
        if (res.status === 200) setMessage({ isShow: true, variant: "success", msg: "Updated!" });
        else setMessage({ isShow: true, variant: "warning", msg: "Warning!" });
        setTimeout(() => {
          setMessage({ isShow: false, variant: "", msg: "" });
        }, 2000);
      })
      .catch((error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage({ isShow: true, variant: "error", msg: resMessage });
      });
  }
  function handleDelete() {
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (confirm("Are you sure you want to delete this blog?")) {
      // Save it!
      RequestService.remove("private/blog", id)
        .then((res) => {
          if (res.status === 200) setMessage({ isShow: true, variant: "success", msg: "Deleted!" });
          else setMessage({ isShow: true, variant: "warning", msg: "Warning!" });
          setTimeout(() => {
            history("/blogs");
            setMessage({ isShow: false, variant: "", msg: "" });
          }, 2000);
        })
        .catch((error) => {
          const resMessage =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          setMessage({ isShow: true, variant: "error", msg: resMessage });
        });
    }
  }
  function handleAdd() {
    const data = { title, image, content, url };
    RequestService.create("private/blog", data)
      .then((res) => {
        if (res.status === 200) setMessage({ isShow: true, variant: "success", msg: "Created!" });
        else setMessage({ isShow: true, variant: "warning", msg: "Warning!" });
        setTimeout(() => {
          history(`/blogs/${res.data.result.url}`);
          setMessage({ isShow: false, variant: "", msg: "" });
        }, 2000);
      })
      .catch((error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage({ isShow: true, variant: "error", msg: resMessage });
      });
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {title}
                </MDTypography>
              </MDBox>
              <MDBox mx={2} mt={4} mb={1} py={0} px={0}>
                <MDInput
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                    setUrl(slugify(e.target.value));
                  }}
                  type="text"
                  variant="outlined"
                  label="Blog Title"
                  fullWidth
                />
              </MDBox>
              <MDBox mx={2} mt={1} mb={1} py={0} px={0}>
                <MDInput
                  value={image}
                  onChange={(e) => setImage(e.target.value)}
                  type="text"
                  variant="outlined"
                  label="Image"
                  fullWidth
                />
              </MDBox>
              <MDBox mx={2} mt={1} mb={1} py={0} px={0}>
                <ReactQuill theme="snow" value={content} onChange={setContent} />
              </MDBox>
              <MDBox mx={2} mt={1} mb={3} py={0} px={0}>
                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={() => (id ? handleSave() : handleAdd())}
                >
                  <Icon>save</Icon>&nbsp; Save
                </MDButton>{" "}
                <MDButton variant="gradient" color="error" onClick={() => handleDelete()}>
                  <Icon>delete</Icon>&nbsp; Delete
                </MDButton>
                {message.isShow ? <MDAlert color={message.variant}>{message.msg}</MDAlert> : ""}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default BlogEdit;
