/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
// Services
import RequestService from "services/request.service";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Icon } from "@mui/material";

export default function data() {
  const [rows, setRows] = useState([]);
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  useEffect(() => {
    let isApiSubscribed = true;
    const results = [];
    RequestService.getAll("public/blog").then((res) => {
      if (isApiSubscribed) {
        if (res.data.result.length > 0) {
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < res.data.result.length; index++) {
            const element = res.data.result[index];
            results.push({
              title: <Author image={element.image} name={element.title} email={element.title} />,
              // function: <Job title="Manager" description="Organization" />,
              created: (
                <MDTypography variant="caption" color="text" fontWeight="medium">
                  <Link to={`/blogs/${element.id}`}>
                    {new Date(element.createdAt).toLocaleString("tr-TR")}
                  </Link>
                </MDTypography>
              ),
              updated: (
                <MDTypography variant="caption" color="text" fontWeight="medium">
                  <Link to={`/blogs/${element.id}`}>
                    {new Date(element.updatedAt).toLocaleString("tr-TR")}
                  </Link>
                </MDTypography>
              ),
              action: (
                <MDTypography variant="caption" color="text" fontWeight="medium">
                  <Link to={`/blogs/${element.url}`}>
                    <Icon>edit</Icon> Edit
                  </Link>
                </MDTypography>
              ),
            });
          }
          setRows(results);
        }
      }
    });

    return () => {
      isApiSubscribed = false;
    };
  }, []);
  return {
    columns: [
      { Header: "title", accessor: "title", width: "45%", align: "left" },
      { Header: "created", accessor: "created", align: "center" },
      { Header: "updated", accessor: "updated", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows,
  };
}
