import { Navigate, Outlet } from "react-router-dom";
import { isAuthenticated } from "./isAuthenticated";

function ProtectedRoutes() {
  const auth = isAuthenticated();

  return auth ? <Outlet /> : <Navigate to="/authentication/sign-in" />;
}

export default ProtectedRoutes;
