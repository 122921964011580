import api from "./api";
import TokenService from "./token.service";

const register = (password2, email, password) =>
  api.post("private/register", {
    password2,
    email,
    password,
  });

const login = (email, password) =>
  api
    .post("public/login", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.token) {
        TokenService.setUser(response.data);
      }

      return response.data;
    });

const logout = () => TokenService.removeUser();

const getCurrentUser = () => JSON.parse(localStorage.getItem("user"));

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
};

export default AuthService;
