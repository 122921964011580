import api from "./api";

const create = (url, data) => api.post(url, data);

const update = (url, id, data) => api.put(`${url}/${id}`, data);
const get = (url, id) => api.get(`${url}/${id}`);
const getAll = (url) => api.get(url);
const remove = (url, id) => api.delete(`${url}/${id}`);
const RequestService = {
  create,
  update,
  get,
  getAll,
  remove,
};

export default RequestService;
